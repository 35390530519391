$(document).ready(function() {
  (function($) {
    "use strict";
    $(function() {
      // sidebar-icon-only class
      $(document).on("mouseenter mouseleave", ".sidebar .nav-item", function(
        ev
      ) {
        var body = $("body");
        var sidebarIconOnly = body.hasClass("sidebar-icon-only");
        var horizontalMenu = body.hasClass("horizontal-menu");
        var sidebarFixed = body.hasClass("sidebar-fixed");
        if (!("ontouchstart" in document.documentElement)) {
          if (sidebarIconOnly || horizontalMenu) {
            if (sidebarFixed) {
              if (ev.type === "mouseenter") {
                body.removeClass("sidebar-icon-only");
              }
            } else {
              var $menuItem = $(this);
              if (ev.type === "mouseenter") {
                $menuItem.addClass("hover-open");
              } else {
                $menuItem.removeClass("hover-open");
              }
            }
          }
        }
      });
      // toggling left side bar
      $(".navbar-toggler").click(function() {
        if ($("body").hasClass("sidebar-icon-only")) {
          $("body").removeClass("sidebar-icon-only");
        } else {
          $("body").addClass("sidebar-icon-only");
        }
      });
      // minimized page - right side
      $('[data-toggle="offcanvas"]').on("click", function() {
        $(".sidebar-offcanvas").toggleClass("active");
      });
    });
  })(jQuery);
});
